<div
    class="flex h-10 flex-row items-center gap-2 truncate px-1 mx-1 rounded-lg hover:bg-gray-700/20 dark:hover:bg-gray-400/20"
    [ngClass]="isDraggable ? 'cursor-pointer' : ''"
>
    <a
        class="flex grow items-center gap-2 truncate text-gray-700 dark:text-gray-100"
        [ngClass]="isDraggable ? 'pointer-events-none' : ''"
        [routerLink]="shortcut.url"
    >
        <div class="h-6 w-6 min-w-[24px]">
            <mat-icon class="!h-full !w-full" [svgIcon]="shortcut.icon" />
        </div>
        <span class="grow text-lg">
            {{ shortcut.name | translate }}
        </span>
    </a>
    @if (shortcut.pinId !== undefined && !isDraggable) {
        <sz-icon-button
            icon="pin"
            size="small"
            color="pin"
            (click)="removePin.emit()"
        />
    }
    @if (isDraggable) {
        <div class="flex h-6 w-6 min-w-[24px]">
            <mat-icon class="!h-5 !w-5" svgIcon="drag-horizontal" />
        </div>
    }
</div>
